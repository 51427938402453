.grid-theme {
  .grid-container {
    height: 92vh;
    overflow-y: auto;
    transition: all 0.4s;

    @media(max-width: 768px) {
      height: 100vh;
    }
  }
  .grid-container > div {
    margin: 90px 0 0;
    width: 82vw;
    float: right;
    transition: all 0.4s;

    @media(max-width: 768px) {
      width: 100vw;
    }

    .grid-item {
      width: calc(100% / 5);
      height: calc(90vh / 3.9);
      border: 1px solid #323341;
      padding: 20px;
      margin: 0 -1px -1px 0;
      display: flex;
      float: left;
      flex-direction: column;
      justify-content: space-between;
      transition: all 0.4s;

      @media(max-width: 768px) {
        height: 40vw;
      }

      h2 {
        font-size: 2.8vw;

        @media(max-width: 768px) {
          font-size: 7vw;
        }
      }

      &.small-text h2 {
        font-size: 2.6vw;

        @media(max-width: 768px) {
          font-size: 5.5vw;
        }
      }

      strong {
        font-size: 1.5vw;
        font-weight: 500;
        opacity: 0.8;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        position: relative;

        @media(max-width: 768px) {
          font-size: 20px;
        }

        .trend {
          position: absolute;
          right: 0;
          bottom: 0;
        }

        .image {
          width: 30px !important;
          height: 30px !important;
          overflow: hidden;
          margin: 0 10px 0 0;
          position: relative;

          @media(max-width: 768px) {
            width: 5vw !important;
            height: 5vw !important;
            margin-right: 10px;
          }

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            position: absolute;
            top: 0;
          }
        }
      }

      &:hover {
        background: #323341;
      }

      &:first-child {
        background: #21439d;
      }
      &:nth-child(2) {
        background: #482974;
      }
      &:nth-child(3) {
        background: #282142;
      }
    }
  }

  .grid-container.grid-fullscreen {
    height: 100vh !important;
    transition: all 0.4s;
  }
  .grid-container.grid-fullscreen > div {
    width: 100vw;
    float: none;
    margin: 0;
    transition: all 0.4s;

    .grid-item {
      width: 22.2%;
      height: calc(100vh / 3.45);

      @media(max-width: 768px) {
        height: 40vw;
      }

      h2 {
        font-size: 4vw;

        @media(max-width: 768px) {
          font-size: 7vw;
        }
      }

      &.small-text h2 {
        font-size: 3.5vw;

        @media(max-width: 768px) {
          font-size: 5.5vw;
        }
      }

      strong {
        font-size: 1.6vw;

        @media(max-width: 768px) {
          font-size: 4vw;
        }

        .image {
          width: 1.8vw !important;
          height: 1.8vw !important;
          margin-right: 15px;

          @media(max-width: 768px) {
            width: 5vw !important;
            height: 5vw !important;
            margin-right: 10px;
          }
        }
      }
    }
  }
}
