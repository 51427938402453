.popup-content {
  margin: auto;
  width: 50%;
  padding: 35px;
  min-height: 200px;
  border-radius: 8px;
  backdrop-filter: blur(20px);
  background: rbga(255,255,255,0.1);
  box-shadow: rgb(0 0 0 / 25%) 0px 40px 80px, rgb(255 255 255 / 25%) 0px 0px 0px 0.5px inset;

  @media(max-width: 480px) {
    width: 75%;
  }
}
.popup-title {
  margin-bottom: 2rem;
}
.popup-arrow {
  color: rgb(255, 255, 255);
}
[role='tooltip'].popup-content {
  width: 200px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 0px 3px;
}

.popup-overlay {
  background: rgba(0, 0, 0, 0.5);
}
[data-popup='tooltip'].popup-overlay {
  background: transparent;
}

.close-popup {
  float: right;
  position: relative;
  top: -10px;
  right: -10px;
  cursor: pointer;
  opacity: 0.6;

  &:hover {
    opacity: 1;
  }
}
