#xs-sidebar {
  width: 20%;
  height: 100%;
  position: fixed;
  left: 2rem;
  top: 10rem;
  z-index: 2;
  transition: transform 0.6s;
  transform: translateX(0);

  &.fullscreen {
    transform: translateX(-200%);
    transition: transform 0.6s;
  }

  .close-menu {
    display: none;
  }

  &.show {
    transition: transform 0.6s;
    transform: translateX(0);
    left: 0;
    top:0;
    background: #000;
    z-index: 3;
    padding: 30px;

    .close-menu {
      background: none;
      color: #fff;
      border: none;
      float: right;
      font-size: 28px;
      margin: 0 50px 0 0;
      position: relative;
      z-index: 2;
      cursor: pointer;
      display: block;
    }
  }

  @media(max-width: 960px) {
    transform: translateX(-200%);
    transition: transform 0.6s;
    width: 100%;
  }

  .default-page-opt {
    svg {
      position: relative;
      top: 2px;
      margin-right: 8px;
    }
  }

  .xs-sidebar__menu {
    margin-left: 0;
    padding-left: 0;

    li {
      margin-bottom: 2rem;
      opacity: 0.6;

      &:hover {
        opacity: 1;
      }

      &:before {
        display: none;
      }

      svg {
        position: relative;
        top: 2px;
        margin-right: 8px;
      }
    }
  }

  .l1-styles {
    li {
      cursor: pointer;
      opacity: 0.65;

      &.checked,
      &:hover {
        opacity: 1;
      }
    }
  }

  .default-styles {
    width: 300px;

    .bg-options {
      height: 400px;
      overflow-y: auto;
      overflow-x: hidden;
      padding-right: 10px;

      /* width */
      &::-webkit-scrollbar {
        width: 5px;
      }

      /* Track */
      &::-webkit-scrollbar-track {
        background: #222;
      }

      /* Handle */
      &::-webkit-scrollbar-thumb {
        background: #666;
      }

      /* Handle on hover */
      &::-webkit-scrollbar-thumb:hover {
        background: #aaa;
      }
    }

    .image-radio.checked:before {
      transform: scale(0.7);
      top: 0px;
      right: 0px;
      font-size: 14px;
      font-weight: 600;
    }

     strong {
       margin-bottom: 20px;
       display: block;
     }

     .image {
       border: 1px solid rgba(255,255,255,0.2);

       img {
         width: 100%;
         height: 100%;
         object-fit: cover;
       }
     }
  }
}

.ph-badge {
  position: absolute;
  bottom: 250px;
}

.toggle-setting {
  display: none;

  @media(max-width: 960px) {
    position: absolute;
    top: -60px;
    left: -3vw;
    z-index: 2;
    display: block;
  }
}
