@function pc-vw($target) {
  $vw-context: (1946*.01) * 1px;
  @return ($target/$vw-context) * 1vw;
  @return $target;
}

@function mobile-vw($target) {
  $vw-context: (768*.01) * 1px;
  @return ($target/$vw-context) * 1vw;
  @return $target;
}


.L1-theme-bg{
  width: 100%;
  height: 100%;
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  content: '';
  opacity: .08;
  z-index: -1;

  img {
    width: 100vw;
    height: 100vh;
    object-fit: cover;
  }
}
.L1-theme {
  font-family: 'Barlow Condensed', sans-serif;
  position: relative;
  height: 90%;
  display: flex;
  align-items: center;
  justify-content: center;
  right: 0;
  top: -2vw;

  @media(max-width: 960px) {
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    height: 90%;
  }
  @media(max-width: 768px) {
    left: auto;
    right: 0;
    top: 50px;
    transform: translate(0,0);
  }

  .L1 {
    position: relative;
    top: 2.5vw;
    left: 50%;
    transform: translate(-25%);

    @media(max-width: 768px) {
      left: auto;
      right: mobile-vw(-70px);
      transform: translate(0,0);
    }

    .mockup {
      position: relative;
      height: pc-vw(485.13px);

      img {
        width: pc-vw(1800px);
        height: auto;
      }
    }

    .screen {
      width: pc-vw(540px);
      height: pc-vw(415px);
      background: #27282D;
      position: absolute;
      z-index: 2;
      left: 2vw;
      top: 5.7vw;

      .ticker-slider .swiper,
      .ticker-slider .swiper-slide {
        height: pc-vw(415px);
      }

      @media(max-width: 768px) {
        width: mobile-vw(390px);
        height: mobile-vw(305px);
        left: 3.5vw;
        top: 10vw;

        .ticker-slider .swiper-slide {
          height: mobile-vw(305px);
        }
      }
    }

    .loading {
      display: flex;
      width: 100%;
      height: 100%;
      align-items: center;
      justify-content: center;
      font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    }

  }

  .clock {
    display: flex;
    width: 15%;
    font-weight: 500;
    position: relative;

    &:before {
      content: '';
      width: 2px;
      height: 100%;
      background: rgba(255,255,255, 0.1);
      display: block;
      position: absolute;
      left: -1.5vw;
      top: 0;
    }

    time {
      line-height: 1;
      font-size: 1.9vw;

      span {
        display: block;
        color: #fff;
      }

      span.s,
      small {
        display: none;
      }
    }

    sub {
      position: relative;
      color: #fff;
      width: 30px;
      position: absolute;
      bottom: -.03vw;
      left: 2.2vw;
    }

    @media(max-width: 768px) {
      width: 18%;

      &:before {
        left: mobile-vw(-30px);
      }
      time {
        font-size: mobile-vw(30px);
      }
      sub {
        left: mobile-vw(40px);
        bottom: mobile-vw(-3px);
      }
    }

    @media(max-width: 480px) {
      sub {
        bottom: mobile-vw(-6px);
      }
    }

  }

  .screen-page {
    width: 100%;
    height: auto;
    background: #27282D;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
  }

  .hd {
    height: auto;
    overflow: hidden;
    margin: 0 2vw 1vw;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media(max-width: 768px) {
      margin-bottom: mobile-vw(20px);
    }

    @media(max-width: 480px) {
      margin-top: mobile-vw(-10px);
      margin-bottom: mobile-vw(0px);
    }
  }

  .coin {
    display: flex;
    width: 60%;
    flex-wrap: wrap;
    float: left;
    color: #fff;

    p {
      line-height: 1.2;
    }

    img {
      margin-right: 1vw;
      width: pc-vw(80px);
    }

    span {
      display: block;
      font-family: 'Barlow Condensed', sans-serif;
      font-weight: 600;
      font-size: 1.7vw;
      text-transform: uppercase;
    }

    @media(max-width: 768px) {
      img {
        margin-right: mobile-vw(20px);
        width: mobile-vw(70px);
      }

      span {
        font-size: mobile-vw(30px);
      }
    }
  }

  .data {
    background: #777B9E;
    border-radius: 8px;
    margin: 1vw 2vw;
    font-size: 4.5vw;
    text-align: center;
    color: #fff;

    &.downtrend {
      background: #D63D31;
    }

    &.uptrend {
      background: #1FB87F;
    }

    &:first-letter {
      font-size: 4.5vw;
      margin-right: 1vw;
    }

    @media(min-width: 2010px) {
      font-size: 120px;

      &:first-letter {
        font-size: 90px;
        margin-right: 10px;
      }
    }

    @media(max-width: 768px) {
      font-size: mobile-vw(70px);

      &:first-letter {
        font-size: mobile-vw(50px);
      }
    }

  }

  .coins {
    position: absolute;
    z-index: 2;
    width: 100%;
    height: auto;
    overflow-x: auto;
    bottom: 0;
    display: flex;
    justify-content: center;

    /* Hide scrollbar for IE, Edge and Firefox */
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */

    /* Hide scrollbar for Chrome, Safari and Opera */
    &::-webkit-scrollbar {
      display: none;
    }

    @media(max-width: 580px) {
      justify-content: flex-start;

      .coins-wrapper {
        width: 140%;
        display: flex;
        flex-wrap: nowrap;
      }
    }

    .image {
      margin: 0 10px;
      float: left;
      width: 32px;

      img {
        width: 32px;
        height: 32px;
        margin: 0;
        cursor: pointer;
        opacity: 0.5;

        &:hover {
          opacity: 1;
        }
      }

      &.checked img {
        opacity: 1;
      }
    }

  }
}
