#xc-footer {
  padding: 0 2rem;
  text-align: center;
  height: 4rem;
  display: flex;
  align-items: center;
  position: relative;
  z-index: 2;
  justify-content: space-between;
  transition: transform 0.6s;
  transform: translateY(0);

  @media(max-width: 960px) {
    display: none;
  }

  &.fullscreen {
    transform: translateY(6rem);
    transition: transform 0.6s;
  }

  .feature-request {
    font-size: 0.9rem;

    svg {
      margin-right: 6px;
      position: relative;
      top: 2px;
    }
  }

  .copyright {
    font-size: 0.7rem;
    opacity: 0.7;
  }

  .socials {
    svg {
      position: relative;
      top: 2px;
      width: .9rem;
    }
    .link {
      margin-left: 1.5rem;

      &:hover {
        opacity: 0.5;
      }
    }
  }
}
