#xc-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 6rem;
  padding: 0 2rem;
  position: relative;
  z-index: 2;

  @media(max-width: 960px) {
    padding: 0 15px 0 40px;
  }

  &.fullscreen {
    .logo, nav {
      transform: translateY(-6rem);
      transition: transform 0.6s;
    }
  }

  .logo {
    font-size: 1.5rem;
    transition: transform 0.6s;
    transform: translateY(0);
    width: 100px;
    height: 23px;


    @media(max-width: 960px) {
      margin-left: 10px;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  nav {
    width: 95%;
    transition: transform 0.6s;
    transform: translateY(0);

    ul {
      list-style: none;
      text-align: right;

      li {
        display: inline-block;
        margin-left: 1.8rem;
        position: relative;

        &:before {
          display: none;
        }

        svg {
          position: relative;
          top: 4px;
        }

        &:last-child:after {
            content: '|';
            opacity: 0.3;
            font-size: .8rem;
            position: relative;
            right: -20px;
            top: -1px;
        }
      }

    }

    @media(max-width: 960px) {
      display: none;
    }
  }

  .view-mode {
    margin-left: 30px;
    position: relative;
    top: -1px;
    cursor: pointer;

    .toggle-menu {
      display: none;
    }

    @media(max-width: 960px) {
      top: 0;

      .toggle-menu {
        display: inline-block;
        margin-right: 20px;
        transition: transform 0.4s;
        transform: translateY(0px);

        &.fullscreen {
          transform: translateY(-80px);
          transition: transform 0.4s;
        }
      }
    }
  }

  #mobile-menu {
    width: 100%;
    height: 100%;
    background: #000;
    position: fixed;
    z-index: 9;
    left: 0;
    top: 0;

    nav {
      display: block;
      left: 0;
      width: 100%;
      top: 40px;
      position: relative;

      ul {
        text-align: left;
        margin: 0 20px;

        li {
          width: 100%;
          margin-left: 0;
          font-size: 14px;
          border-bottom: 1px solid #333;
          padding-bottom: 10px;
        }
        li:last-child:after {
          display: none;
        }
      }
    }

    .close-menu {
      font-size: 2em;
      float: right;
      margin: 23px 20px 0 0;
      position: relative;
      z-index: 2;
      cursor: pointer;
    }
  }
}
