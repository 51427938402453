.default-theme {
  height: 100%;
  background: #000;
  position: relative;

  .bg{
    width: 100%;
    height: 100%;
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    content: '';
    opacity: .55;
    z-index: 1;

    video,
    img {
      width: 100vw;
      height: 100vh;
      object-fit: cover;
    }
  }

  .data {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 100%;
    position: relative;
    top: -6vw;
    left: 0;
    z-index: 2;
    font-family: 'Barlow Condensed', sans-serif;

    .ticker-slider {
      width: 100%;

      .swiper-slide {
        display: flex;
        justify-content: center;
      }
    }

    .loading {
      font-size: 16px;
      font-weight: 600;
      color: #1BAB4C;
      margin-bottom: 30px;
      font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    }

    .price {
      font-size: 9vw;
      font-weight: 500;
      color: #1BAB4C;

      &.downtrend {
        color: #D63D31;
      }

      &.uptrend {
        color: #1BAB4C;
      }
    }

    time {
      font-weight: 400;
      font-size: 4vw;
      margin-top: -2vw;

      sub {
        display: none;
      }
    }

    @media(max-width: 960px) {
      .price {
        font-size: 16vw;
        line-height: 0.6;
      }

      time {
        font-size: 6vw;
      }
    }
    @media(max-width: 768px) {
      .price {
        font-size: 14vw;
        line-height: 1.5;
      }
    }
  }

  .coins {
    position: absolute;
    left: 50%;
    bottom: 5vw;
    transform: translateX(-50%);
    z-index: 2;

    .image {
      display: inline-block;
      margin: 0 10px;

      img {
        width: 25px;
        height: 25px;
        margin: 0;
        cursor: pointer;
        opacity: 0.5;

        &:hover {
          opacity: 1;
        }
      }

      &.checked img {
        opacity: 1;
      }
    }

    @media(max-width: 960px) {
      text-align: center;
      bottom: 10vw;
      width: 100%;

      .image {
        margin: 10px 12px;

        img {
          width: 20px;
          opacity: 0.5;
        }

        &:hover img {
          opacity: 1;
        }
      }

      .image.checked img {
        opacity: 1;
      }
    }

    @media(max-width: 768px) {
      bottom: 15vw;
      left: 0;
      width: 100%;
      transform: translateX(0);
      overflow-x: auto;
      /* Hide scrollbar for IE, Edge and Firefox */
      -ms-overflow-style: none;  /* IE and Edge */
      scrollbar-width: none;  /* Firefox */

      /* Hide scrollbar for Chrome, Safari and Opera */
      &::-webkit-scrollbar {
        display: none;
      }

      .coins-wrapper {
        display: flex;
        flex-wrap: nowrap;
        overflow-x: auto;
        justify-content: center;
      }
    }

    @media(max-width: 480px) {
      bottom: 25vw;

      .coins-wrapper {
        width: 120%;
      }
    }
  }
}
