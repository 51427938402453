body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* latin */
@font-face {
  font-family: 'Barlow Condensed';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(../../assets/fonts/Barlow-Condensed-bold.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: 'Barlow Condensed';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(../../assets/fonts/Barlow-Condensed-medium.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

.hidden {
  display: none !important;
}

@media(min-width: 769px) {
  .only-pc {
    display: block;
  }
  .only-mb {
    display: none;
  }
}
@media(max-width: 768px) {
  .only-mb {
    display: block;
  }
  .only-pc {
    display: none;
  }
}

.xc-layout {
  height: 100vh;
  overflow: hidden;
}

.xc-content {
  height: calc(100vh - 10em);
}

.xc-stage {
  position: relative;
  width: 90vw;
  height: 100%;
  margin: auto;
  display: flex;
  align-items: stretch;
}

.main {
  width: 100%;
  height: 100%;
  float: right;
  overflow-x: hidden;
  overflow-y: auto;
  position: fixed;
  top: 0;
  left: 0;
}

.clickable,
.image-radio {
  cursor: pointer;
}


.image-radio.checked:before {
  width: 30px;
  height: 30px;
  text-align: center;
  line-height: 30px;
  content: "√";
  position: absolute;
  top: 10px;
  right: 10px;
  color: #fff;
  background: #A57452;
  border-radius: 20px;
  display: block;
  z-index: 2;
}

.radio-group {
  flex-wrap: wrap;

  .radio {
    margin-left: 0 !important;
    margin-right: 20px !important;
    margin-bottom: 10px !important;
  }
}
